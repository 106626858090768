<script>
import { BAvatar, BBadge } from 'bootstrap-vue'

export default {
  name: 'UserCard',
  components: {
    BAvatar,
    BBadge
  },
  props: {
    user: {}
  }
}
</script>
<template>
  <div v-if="user" class="d-flex align-items-center">
    <b-avatar default="''" :src="user?.avatar === '' ? '/avatar.svg' : user?.avatar" class="mr-1">
    </b-avatar>
    <div>
      <div class="font-weight-bolder">
        {{ user?.name }}
        <b-badge variant="light-secondary">{{
            user?.employee_number
          }}</b-badge>
      </div>
        <a :href="'mailto:' + (user?.email || '')" class="font-small-2 font-weight-bold">
          {{ user?.email }}
        </a>

      <template v-if="user?.designation?.name">
        <div class="font-small-3 font-weight-bold mt-half">
          <b-badge variant="light-primary">
            {{ user?.designation?.name }}
          </b-badge>
        </div>
      </template>

      <template v-if="user?.department?.name">
        <div class="font-small-3 mb-half mt-half">
          <b-badge variant="light-primary">
            {{ user?.department?.name }}
          </b-badge>
        </div>
      </template>


    </div>
  </div>
  <div class="text-center" v-else>
    <b-badge variant="light-danger">
      Not Assigned
    </b-badge>
  </div>
</template>

<style scoped lang="scss">
 .mb-half{
   margin-bottom: 5px !important;
 }
 .mt-half{
   margin-top: 5px !important;
 }
</style>
