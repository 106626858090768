<script>
import { BAvatar, BBadge } from 'bootstrap-vue'

export default {
  name: 'UserSelect',
  components: {
    BAvatar,
    BBadge
  },
  props: {
    user: {}
  }
}
</script>
<template>
  <div class="d-flex align-items-center">
    <b-avatar :src="(user?.avatar === '' || !user?.avatar) ? '/avatar.svg' : user?.avatar" class="mr-1">
    </b-avatar>
    <div>
      <div class="font-weight-bolder">
        {{ user?.name }}
        <b-badge variant="light-primary">{{
            user?.employee_number
          }}</b-badge>

         <b-badge class="ml-1" v-if="user.disabled" variant="danger">Inactive</b-badge>
      </div>
      <div class="font-small-2">
        {{ user?.email }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
