var render = function () {
  var _vm$user, _vm$user2, _vm$user3, _vm$user4, _vm$user5, _vm$user6, _vm$user7, _vm$user7$designation, _vm$user8, _vm$user8$designation, _vm$user9, _vm$user9$department, _vm$user10, _vm$user10$department;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "default": "''",
      "src": ((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.avatar) === '' ? '/avatar.svg' : (_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.avatar
    }
  }), _c('div', [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s((_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : _vm$user3.name) + " "), _c('b-badge', {
    attrs: {
      "variant": "light-secondary"
    }
  }, [_vm._v(_vm._s((_vm$user4 = _vm.user) === null || _vm$user4 === void 0 ? void 0 : _vm$user4.employee_number))])], 1), _c('a', {
    staticClass: "font-small-2 font-weight-bold",
    attrs: {
      "href": 'mailto:' + (((_vm$user5 = _vm.user) === null || _vm$user5 === void 0 ? void 0 : _vm$user5.email) || '')
    }
  }, [_vm._v(" " + _vm._s((_vm$user6 = _vm.user) === null || _vm$user6 === void 0 ? void 0 : _vm$user6.email) + " ")]), (_vm$user7 = _vm.user) !== null && _vm$user7 !== void 0 && (_vm$user7$designation = _vm$user7.designation) !== null && _vm$user7$designation !== void 0 && _vm$user7$designation.name ? [_c('div', {
    staticClass: "font-small-3 font-weight-bold mt-half"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$user8 = _vm.user) === null || _vm$user8 === void 0 ? void 0 : (_vm$user8$designation = _vm$user8.designation) === null || _vm$user8$designation === void 0 ? void 0 : _vm$user8$designation.name) + " ")])], 1)] : _vm._e(), (_vm$user9 = _vm.user) !== null && _vm$user9 !== void 0 && (_vm$user9$department = _vm$user9.department) !== null && _vm$user9$department !== void 0 && _vm$user9$department.name ? [_c('div', {
    staticClass: "font-small-3 mb-half mt-half"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$user10 = _vm.user) === null || _vm$user10 === void 0 ? void 0 : (_vm$user10$department = _vm$user10.department) === null || _vm$user10$department === void 0 ? void 0 : _vm$user10$department.name) + " ")])], 1)] : _vm._e()], 2)], 1) : _c('div', {
    staticClass: "text-center"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" Not Assigned ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }