var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterAllUserIdOption,
      "reduce": function reduce(member) {
        return member.id;
      },
      "label": "name",
      "placeholder": " By All Member"
    },
    on: {
      "input": _vm.loadSelectedMember
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.filterMemberId,
      callback: function callback($$v) {
        _vm.filterMemberId = $$v;
      },
      expression: "filterMemberId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterProjectIdOption,
      "reduce": function reduce(task) {
        return task.id;
      },
      "label": "name",
      "placeholder": "Project Name"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterProjectId,
      callback: function callback($$v) {
        _vm.filterProjectId = $$v;
      },
      expression: "filterProjectId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.departmentIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Department"
    },
    on: {
      "input": _vm.setDepartmentWiseInfo
    },
    model: {
      value: _vm.filterDepartmentId,
      callback: function callback($$v) {
        _vm.filterDepartmentId = $$v;
      },
      expression: "filterDepartmentId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.filterMilestoneBoardIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Milestone Board"
    },
    on: {
      "input": _vm.loadSelectedMilestoneBoard
    },
    model: {
      value: _vm.filterBoardListMilestoneId,
      callback: function callback($$v) {
        _vm.filterBoardListMilestoneId = $$v;
      },
      expression: "filterBoardListMilestoneId"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "second-layer-margin"
  }, [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterAllUserIdOption,
      "reduce": function reduce(member) {
        return member.id;
      },
      "label": "name",
      "placeholder": " By Supervisor"
    },
    on: {
      "input": _vm.loadSelectedMember
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.filterAllMemberSupervisorId,
      callback: function callback($$v) {
        _vm.filterAllMemberSupervisorId = $$v;
      },
      expression: "filterAllMemberSupervisorId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "searchTerm"
    }
  }, [_c('b-form-input', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "placeholder": "Search By Board Name ...",
      "type": "text"
    },
    on: {
      "input": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.milestoneTaskBoardStatusConstants,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Select Milestone Status"
    },
    on: {
      "input": _vm.loadSelectedMilestoneStatus
    },
    model: {
      value: _vm.filterMilestoneStatus,
      callback: function callback($$v) {
        _vm.filterMilestoneStatus = $$v;
      },
      expression: "filterMilestoneStatus"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Created Date Range",
      "config": {
        mode: 'range'
      }
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1)], 1), _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-between"
  }, [_c('b-dropdown', {
    attrs: {
      "text": _vm.selectedPersonalFilterText,
      "variant": 'primary',
      "size": "sm"
    }
  }, [_c('b-dropdown-item', {
    ref: "personalFilter",
    on: {
      "click": _vm.fullListShow
    }
  }, [_vm._v("All")]), _c('b-dropdown-item', {
    ref: "personalFilter",
    on: {
      "click": _vm.imMySupervision
    }
  }, [_vm._v("My Supervision")]), _c('b-dropdown-item', {
    ref: "personalFilter",
    on: {
      "click": _vm.assignedInTeam
    }
  }, [_vm._v("Assigned in Team")])], 1), _c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.MILESTONE_BOARD_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create ")])] : _vm._e()], 2)], 1), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "styleClass": "vgt-table table-custom-style striped",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row2, _props$row3, _props$row4, _props$row4$milestone, _props$row4$milestone2, _props$row5, _props$row5$milestone, _props$row5$milestone2, _props$row6, _props$row6$milestone, _props$row6$milestone2, _props$row7, _props$row7$milestone, _props$row7$milestone2, _props$row8, _props$row8$milestone, _props$row8$milestone2, _props$row9, _props$row10, _props$row10$departme, _props$row10$departme2, _props$column2, _props$row11, _props$row13, _props$row14, _props$column3, _props$row16, _props$row17, _props$column4, _props$row18, _props$row19, _props$row19$supervis, _props$column5, _props$row20, _props$row21, _props$row22, _props$row23, _vm$authUser, _props$row25, _props$row25$supervis, _props$row25$supervis2, _vm$authUser2, _props$row26, _props$row26$supervis, _props$row26$supervis2;
        return [[(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_title' ? [_c('div', {
          staticClass: "table-cell-margin"
        }, [[_c('div', [_c('b-link', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "color": "black"
          },
          on: {
            "click": function click($event) {
              var _props$row;
              return _vm.onShowPage(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.id);
            }
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.title) + " ")])], 1), _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.setMilestoneBoardType(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.type)))])], 1), props !== null && props !== void 0 && (_props$row4 = props.row) !== null && _props$row4 !== void 0 && (_props$row4$milestone = _props$row4.milestone) !== null && _props$row4$milestone !== void 0 && (_props$row4$milestone2 = _props$row4$milestone.data) !== null && _props$row4$milestone2 !== void 0 && _props$row4$milestone2.title ? _c('div', [_vm._v(" Milestone: "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : (_props$row5$milestone = _props$row5.milestone) === null || _props$row5$milestone === void 0 ? void 0 : (_props$row5$milestone2 = _props$row5$milestone.data) === null || _props$row5$milestone2 === void 0 ? void 0 : _props$row5$milestone2.title) + " "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatMilestoneType(props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : (_props$row6$milestone = _props$row6.milestone) === null || _props$row6$milestone === void 0 ? void 0 : (_props$row6$milestone2 = _props$row6$milestone.data) === null || _props$row6$milestone2 === void 0 ? void 0 : _props$row6$milestone2.is_extra)))])], 1)]) : _c('div', [_c('span', [_vm._v("Milestone: "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-danger"
          }
        }, [_vm._v("N/A")])], 1)]), props !== null && props !== void 0 && (_props$row7 = props.row) !== null && _props$row7 !== void 0 && (_props$row7$milestone = _props$row7.milestone) !== null && _props$row7$milestone !== void 0 && (_props$row7$milestone2 = _props$row7$milestone.data) !== null && _props$row7$milestone2 !== void 0 && _props$row7$milestone2.payment_type ? _c('div', [_vm._v(" Payment Type: "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatType(props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : (_props$row8$milestone = _props$row8.milestone) === null || _props$row8$milestone === void 0 ? void 0 : (_props$row8$milestone2 = _props$row8$milestone.data) === null || _props$row8$milestone2 === void 0 ? void 0 : _props$row8$milestone2.payment_type)))])], 1) : _vm._e(), props !== null && props !== void 0 && (_props$row9 = props.row) !== null && _props$row9 !== void 0 && _props$row9.department ? _c('div', [_vm._v("Department: "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : (_props$row10$departme = _props$row10.department) === null || _props$row10$departme === void 0 ? void 0 : (_props$row10$departme2 = _props$row10$departme.data) === null || _props$row10$departme2 === void 0 ? void 0 : _props$row10$departme2.name) + " ")])], 1) : _c('div', [_c('span', [_vm._v("Department: "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-danger"
          }
        }, [_vm._v("N/A")])], 1)])]], 2)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_members' ? [_c('div', {
          staticClass: "table-cell-margin"
        }, [_c('div', {
          staticClass: "mt-1"
        }, [_c('div', [_vm._l(props.row.team.data, function (member, index) {
          return _c('span', {
            key: index
          }, [index <= 2 ? [_c('b-avatar', {
            staticStyle: {
              "margin": "1px"
            },
            attrs: {
              "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
              "size": "26"
            }
          })] : _vm._e()], 2);
        }), (props === null || props === void 0 ? void 0 : (_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.team_count) > 3 ? _c('span', [_c('b-link', {
          on: {
            "click": function click($event) {
              var _props$row12, _props$row12$team;
              return _vm.showAllAddedMemberModal(props === null || props === void 0 ? void 0 : (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : (_props$row12$team = _props$row12.team) === null || _props$row12$team === void 0 ? void 0 : _props$row12$team.data);
            }
          }
        }, [_vm._v(" + " + _vm._s((props === null || props === void 0 ? void 0 : (_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.team_count) - 3) + " more ")])], 1) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.team_count) > 0 ? _c('b-button', {
          staticClass: "btn-icon rounded-circle",
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              var _props$row15, _props$row15$team;
              return _vm.showAllAddedMemberModal(props === null || props === void 0 ? void 0 : (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : (_props$row15$team = _props$row15.team) === null || _props$row15$team === void 0 ? void 0 : _props$row15$team.data);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UsersIcon"
          }
        })], 1) : _vm._e(), _c('b-button', {
          staticClass: "btn-icon rounded-circle",
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.showAddMemberModal(props === null || props === void 0 ? void 0 : props.row);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserPlusIcon"
          }
        })], 1)], 2)])])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_status' ? [_c('div', {
          staticClass: "mt-1"
        }, [_c('b-dropdown', {
          attrs: {
            "text": _vm.setStatus(props === null || props === void 0 ? void 0 : (_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.status),
            "variant": _vm.setStatusVariant(props === null || props === void 0 ? void 0 : (_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : _props$row17.status),
            "size": "sm",
            "disabled": !_vm.$permissionAbility(_vm.MILESTONE_BOARD_UPDATE_STATUS, _vm.permissions)
          }
        }, _vm._l(_vm.milestoneTaskBoardStatusConstants, function (item) {
          return _c('b-dropdown-item', {
            key: item === null || item === void 0 ? void 0 : item.value,
            on: {
              "click": function click($event) {
                return _vm.updateStatus(props === null || props === void 0 ? void 0 : props.row, item);
              }
            }
          }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")]);
        }), 1)], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) == 'format_supervisor' ? [props !== null && props !== void 0 && (_props$row18 = props.row) !== null && _props$row18 !== void 0 && _props$row18.supervisor ? _c('span', [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row19 = props.row) === null || _props$row19 === void 0 ? void 0 : (_props$row19$supervis = _props$row19.supervisor) === null || _props$row19$supervis === void 0 ? void 0 : _props$row19$supervis.data
          }
        })], 1) : _c('span', [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('span', [_vm._v(" Not Supervisor Assigned ")])])])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'format_task_subtask_count' ? [_c('div', {
          staticClass: "mt-1 mb-1"
        }, [_c('b', [_vm._v("Task: ")]), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(props !== null && props !== void 0 && (_props$row20 = props.row) !== null && _props$row20 !== void 0 && _props$row20.tasks_count ? props === null || props === void 0 ? void 0 : (_props$row21 = props.row) === null || _props$row21 === void 0 ? void 0 : _props$row21.tasks_count : 0))])], 1), _c('div', {
          staticClass: "mt-1"
        }, [_c('b', [_vm._v("SubTask: ")]), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(props !== null && props !== void 0 && (_props$row22 = props.row) !== null && _props$row22 !== void 0 && _props$row22.sub_tasks_count ? props === null || props === void 0 ? void 0 : (_props$row23 = props.row) === null || _props$row23 === void 0 ? void 0 : _props$row23.sub_tasks_count : 0))])], 1)] : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [[_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              var _props$row24;
              return _vm.onShowPage(props === null || props === void 0 ? void 0 : (_props$row24 = props.row) === null || _props$row24 === void 0 ? void 0 : _props$row24.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("Show")])], 1)], _vm.$permissionAbility(_vm.MILESTONE_BOARD_EDIT, _vm.permissions) || ((_vm$authUser = _vm.authUser) === null || _vm$authUser === void 0 ? void 0 : _vm$authUser.id) == (props === null || props === void 0 ? void 0 : (_props$row25 = props.row) === null || _props$row25 === void 0 ? void 0 : (_props$row25$supervis = _props$row25.supervisor) === null || _props$row25$supervis === void 0 ? void 0 : (_props$row25$supervis2 = _props$row25$supervis.data) === null || _props$row25$supervis2 === void 0 ? void 0 : _props$row25$supervis2.id) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.MILESTONE_BOARD_DELETE, _vm.permissions) || ((_vm$authUser2 = _vm.authUser) === null || _vm$authUser2 === void 0 ? void 0 : _vm$authUser2.id) == (props === null || props === void 0 ? void 0 : (_props$row26 = props.row) === null || _props$row26 === void 0 ? void 0 : (_props$row26$supervis = _props$row26.supervisor) === null || _props$row26$supervis === void 0 ? void 0 : (_props$row26$supervis2 = _props$row26$supervis.data) === null || _props$row26$supervis2 === void 0 ? void 0 : _props$row26$supervis2.id) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])]];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '50', '100', '200']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-add-member-form",
      "centered": "",
      "title": "Add Member",
      "hide-footer": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAddMemberModal
    }
  }, [_c('validation-observer', {
    ref: "addMemberValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addMemberValidationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Member",
      "label-for": "user_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "user_id",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user_id",
            "placeholder": "Search by employee name or email",
            "options": _vm.memberIdOptions,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(_ref2) {
              var name = _ref2.name,
                avatar = _ref2.avatar;
              return [_c('span', [_c('b-avatar', {
                staticClass: "mr-1",
                attrs: {
                  "src": avatar === '' ? '/avatar.svg' : avatar,
                  "size": "26"
                }
              }), _vm._v(_vm._s(name))], 1)];
            }
          }], null, true),
          model: {
            value: _vm.selectMemberIds,
            callback: function callback($$v) {
              _vm.selectMemberIds = $$v;
            },
            expression: "selectMemberIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-all-added-member-form",
      "centered": "",
      "title": "Assigned Members",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAllAddedMemberModal
    }
  }, [_c('ul', _vm._l(_vm.assignedMember, function (member, index) {
    return _c('li', {
      key: index
    }, [_c('b-avatar', {
      staticStyle: {
        "margin": "1px"
      },
      attrs: {
        "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
        "size": "26"
      }
    }), _vm._v(" " + _vm._s(member.name) + " ")], 1);
  }), 0)]), _c('b-modal', {
    attrs: {
      "id": "modal-task-board-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit Board Information ' : 'Create New Task Board',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "milestoneTaskBoardValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.milestoneTaskBoardValidationForm($event);
      }
    }
  }, [_vm.modelType == '' ? _c('div', {
    staticClass: "mb-1"
  }, [_c('b-form-checkbox', {
    on: {
      "input": _vm.resetModalFields
    },
    model: {
      value: _vm.isExistingMilestoneSelected,
      callback: function callback($$v) {
        _vm.isExistingMilestoneSelected = $$v;
      },
      expression: "isExistingMilestoneSelected"
    }
  }, [_vm._v(" From Existing Milestone ")])], 1) : _vm._e(), _vm.modelType == '' ? [_vm.isExistingMilestoneSelected ? [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Milestone ",
      "label-for": "milestone_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "milestone ",
      "vid": "milestone_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "milestone_id",
            "placeholder": "Choose A Milestone",
            "options": _vm.milestoneIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name",
            "disabled": _vm.modelType == 'editModel' ? true : false
          },
          on: {
            "input": function input($event) {
              return _vm.setMilestoneBoardTitle(_vm.milestoneId);
            }
          },
          model: {
            value: _vm.milestoneId,
            callback: function callback($$v) {
              _vm.milestoneId = $$v;
            },
            expression: "milestoneId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3259812398)
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Board Title",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title ",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Board Name"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2885248314)
  })], 1)] : [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Board Title",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title ",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Board Name"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2885248314)
  })], 1)]] : [_vm.milestoneId ? [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Milestone ",
      "label-for": "milestone_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "milestone ",
      "vid": "milestone_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          attrs: {
            "id": "milestone_id",
            "placeholder": "Choose A Milestone",
            "options": _vm.milestoneIdOption,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "disabled": _vm.modelType == 'editModel' ? true : false
          },
          model: {
            value: _vm.milestoneId,
            callback: function callback($$v) {
              _vm.milestoneId = $$v;
            },
            expression: "milestoneId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1402631583)
  })], 1)] : [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Board Title ",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title ",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Board Name"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)]], _c('b-form-group', {
    attrs: {
      "label": "Supervisor",
      "label-for": "supervisor_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "supervisor",
      "vid": "supervisor_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          attrs: {
            "id": "supervisor_id",
            "placeholder": "Choose a Supervisor",
            "options": _vm.supervisorIdOption,
            "reduce": function reduce(country) {
              return country.id;
            },
            "label": "name"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectSupervisorId,
            callback: function callback($$v) {
              _vm.selectSupervisorId = $$v;
            },
            expression: "selectSupervisorId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }